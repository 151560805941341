const routers = [
  {
    path: "/",
    redirect: "/login",
  },
  {
    component: "AuthLayout",
    path: "/login",
    name: "Login",
    exact: false,
    childrens: [
      {
        component: "Login",
        path: "/",
        componentPath: "pages/Auth/Login",
        name: "Login",
        auth: false,
        exact: true,
      },
    ],
  },
  {
    component: "AuthLayout",
    path: "/forget-password",
    name: "ForgetPassword",
    exact: false,
    childrens: [
      {
        component: "ForgetPassword",
        path: "/",
        componentPath: "pages/Auth/ForgetPassword",
        name: "ForgetPassword",
        auth: false,
        exact: true,
      },
    ],
  },
  {
    component: "AuthLayout",
    path: "/reset-password",
    name: "ResetPassword",
    exact: false,
    childrens: [
      {
        component: "ResetPassword",
        path: "/",
        componentPath: "pages/Auth/ResetPassword",
        name: "ResetPassword",
        auth: false,
        exact: true,
      },
    ],
  },
  {
    component: "MainLayout",
    path: "/dashboard",
    name: "Dashboard",
    exact: false,
    childrens: [
      {
        component: "Dashboard",
        path: "/",
        componentPath: "pages/Dashboard",
        name: "Dashboard",
        auth: false,
        exact: true,
      },
    ],
  },

  {
    component: "MainLayout",
    path: "/global-variables",
    name: "Global Variables",
    exact: false,
    childrens: [
      {
        component: "GlobalVariables",
        path: "/",
        componentPath: "pages/GlobalVariables",
        name: "GlobalVariables",
        auth: false,
        exact: true,
      },
    ],
  },
  {
    component: "MainLayout",
    path: "/user-management",
    name: "UserManagement",
    exact: false,
    childrens: [
      {
        component: "UserManagement",
        path: "/",
        componentPath: "pages/UserManagement",
        name: "UserManagement",
        auth: false,
        exact: true,
      },
      {
        component: "AddEditRole",
        path: "/add-role",
        componentPath: "pages/UserManagement/AddEditRole",
        name: "AddEditRole",
        auth: false,
        exact: true,
      },
      {
        component: "AddEditRole",
        path: "/edit-role",
        componentPath: "pages/UserManagement/AddEditRole",
        name: "AddEditRole",
        auth: false,
        exact: true,
      },
      {
        component: "AddEditStaff",
        path: "/add-staff",
        componentPath: "pages/UserManagement/AddEditStaff",
        name: "AddEditStaff",
        auth: false,
        exact: true,
      },
      {
        component: "AddEditStaff",
        path: "/edit-staff",
        componentPath: "pages/UserManagement/AddEditStaff",
        name: "AddEditStaff",
        auth: false,
        exact: true,
      },
    ],
  },
  {
    component: "MainLayout",
    path: "/order-management",
    name: "OrderManagement",
    exact: false,
    childrens: [
      {
        component: "OrderManagement",
        path: "/",
        componentPath: "pages/OrderManagement",
        name: "OrderManagement",
        auth: false,
        exact: true,
      },
      {
        component: "ViewOrder",
        path: "/view-order",
        componentPath: "pages/OrderManagement/ViewOrder",
        name: "ViewOrder",
        auth: false,
        exact: true,
      },
    ],
  },
  {
    component: "MainLayout",
    path: "/product-management",
    name: "ProductManagement",
    exact: false,
    childrens: [
      {
        component: "ProductManagement",
        path: "/",
        componentPath: "pages/ProductManagement",
        name: "ProductManagement",
        auth: false,
        exact: true,
      },
      {
        component: "AddEditProduct",
        path: "/add-product",
        componentPath: "pages/ProductManagement/AddEditProduct",
        name: "AddEditProduct",
        auth: false,
        exact: true,
      },
      {
        component: "AddEditProduct",
        path: "/edit-product",
        componentPath: "pages/ProductManagement/AddEditProduct",
        name: "AddEditProduct",
        auth: false,
        exact: true,
      },
      {
        component: "AddEditCategory",
        path: "/add-category",
        componentPath: "pages/ProductManagement/AddEditCategory",
        name: "AddEditCategory",
        auth: false,
        exact: true,
      },
      {
        component: "AddEditCategory",
        path: "/edit-category",
        componentPath: "pages/ProductManagement/AddEditCategory",
        name: "AddEditCategory",
        auth: false,
        exact: true,
      },
      {
        component: "BulkUpload",
        path: "/bulk-upload",
        componentPath: "pages/ProductManagement/BulkUpload",
        name: "BulkUpload",
        auth: false,
        exact: true,
      },
    ],
  },
  {
    component: "MainLayout",
    path: "/appointment-management",
    name: "AppointmentManagement",
    exact: false,
    childrens: [
      {
        component: "AppointmentManagement",
        path: "/",
        componentPath: "pages/AppointmentManagement",
        name: "AppointmentManagement",
        auth: false,
        exact: true,
      },
      {
        component: "ViewAppointment",
        path: "/view-appointment",
        componentPath: "pages/AppointmentManagement/ViewAppointment",
        name: "ViewAppointment",
        auth: false,
        exact: true,
      },
    ],
  },
  {
    component: "MainLayout",
    path: "/customer-management",
    name: "CustomerManagement",
    exact: false,
    childrens: [
      {
        component: "CustomerManagement",
        path: "/",
        componentPath: "pages/CustomerManagement",
        name: "CustomerManagement",
        auth: false,
        exact: true,
      },
      {
        component: "AddEditCustomer",
        path: "/add-customer",
        componentPath: "pages/CustomerManagement/AddEditCustomer",
        name: "AddEditCustomer",
        auth: false,
        exact: true,
      },
      {
        component: "AddEditCustomer",
        path: "/edit-customer",
        componentPath: "pages/CustomerManagement/AddEditCustomer",
        name: "AddEditCustomer",
        auth: false,
        exact: true,
      },
      {
        component: "ViewCustomer",
        path: "/view-customer",
        componentPath: "pages/CustomerManagement/ViewCustomer",
        name: "ViewCustomer",
        auth: false,
        exact: true,
      },
      {
        component: "SearchDoctor",
        path: "/search-doctor",
        componentPath: "pages/CustomerManagement/SearchDoctor",
        name: "SearchDoctor",
        auth: false,
        exact: true,
      },
      {
        component: "ReviewAppointment",
        path: "/review-appointment",
        componentPath: "pages/CustomerManagement/ReviewAppointment",
        name: "ReviewAppointment",
        auth: false,
        exact: true,
      },
      {
        component: "AddEditGroupCustomerGroup",
        path: "/add-customer-group",
        componentPath: "pages/CustomerManagement/AddEditCustomerGroup",
        name: "AddEditGroupCustomerGroup",
        auth: false,
        exact: true,
      },
      {
        component: "AddEditGroupCustomerGroup",
        path: "/edit-customer-group",
        componentPath: "pages/CustomerManagement/AddEditCustomerGroup",
        name: "AddEditGroupCustomerGroup",
        auth: false,
        exact: true,
      },
      {
        component: "ViewAppointment",
        path: "/view-appointment",
        componentPath: "pages/AppointmentManagement/ViewAppointment",
        name: "ViewAppointment",
        auth: false,
        exact: true,
      },
    ],
  },
  {
    component: "MainLayout",
    path: "/service-management",
    name: "ServiceManagement",
    exact: false,
    childrens: [
      {
        component: "ServiceManagement",
        path: "/",
        componentPath: "pages/ServiceManagement",
        name: "ServiceManagement",
        auth: false,
        exact: true,
      },
      {
        component: "AddEditDoctor",
        path: "/add-doctor",
        componentPath: "pages/ServiceManagement/AddEditDoctor",
        name: "AddEditDoctor",
        auth: false,
        exact: true,
      },
      {
        component: "AddEditDoctor",
        path: "/edit-doctor",
        componentPath: "pages/ServiceManagement/AddEditDoctor",
        name: "AddEditDoctor",
        auth: false,
        exact: true,
      },
      {
        component: "ViewDoctor",
        path: "/view-doctor",
        componentPath: "pages/ServiceManagement/ViewDoctor",
        name: "ViewDoctor",
        auth: false,
        exact: true,
      },
      {
        component: "AddEditService",
        path: "/add-service",
        componentPath: "pages/ServiceManagement/AddEditService",
        name: "AddEditService",
        auth: false,
        exact: true,
      },
      {
        component: "AddEditService",
        path: "/edit-service",
        componentPath: "pages/ServiceManagement/AddEditService",
        name: "AddEditService",
        auth: false,
        exact: true,
      },

      {
        component: "AddEditSpecialization",
        path: "/add-specialization",
        componentPath: "pages/ServiceManagement/AddEditSpecialization",
        name: "AddEditSpecialization",
        auth: false,
        exact: true,
      },
      {
        component: "AddEditSpecialization",
        path: "/edit-specialization",
        componentPath: "pages/ServiceManagement/AddEditSpecialization",
        name: "AddEditSpecialization",
        auth: false,
        exact: true,
      },
      {
        component: "AdminConsultationReport",
        path: "/admin-consultation-report",
        componentPath: "pages/ServiceManagement/AdminConsultationReport",
        name: "AdminConsultationReport",
        auth: false,
        exact: true,
      },
    ],
  },
  {
    component: "MainLayout",
    path: "/coupon-management",
    name: "CouponManagement",
    exact: false,
    childrens: [
      {
        component: "CouponManagement",
        path: "/",
        componentPath: "pages/CouponManagement",
        name: "CouponManagement",
        auth: false,
        exact: true,
      },
      {
        component: "AddEditCoupon",
        path: "/add-coupon",
        componentPath: "pages/CouponManagement/AddEditCoupon",
        name: "AddEditCoupon",
        auth: false,
        exact: true,
      },
      {
        component: "AddEditCoupon",
        path: "/edit-coupon",
        componentPath: "pages/CouponManagement/AddEditCoupon",
        name: "AddEditCoupon",
        auth: false,
        exact: true,
      },
    ],
  },
  {
    component: "MainLayout",
    path: "/articles-management",
    name: "Articles",
    exact: false,
    childrens: [
      {
        component: "Articles",
        path: "/",
        componentPath: "pages/Articles",
        name: "Articles",
        auth: false,
        exact: true,
      },
      {
        component: "AddEditArticlesCategory",
        path: "/add-article-category",
        componentPath: "pages/Articles/AddEditArticlesCategory",
        name: "AddEditArticlesCategory",
        auth: false,
        exact: true,
      },
      {
        component: "AddEditArticlesCategory",
        path: "/edit-article-category",
        componentPath: "pages/Articles/AddEditArticlesCategory",
        name: "AddEditArticlesCategory",
        auth: false,
        exact: true,
      },
      {
        component: "AddEditArticles",
        path: "/add-articles",
        componentPath: "pages/Articles/AddEditArticles",
        name: "AddEditArticles",
        auth: false,
        exact: true,
      },
      {
        component: "AddEditArticles",
        path: "/edit-articles",
        componentPath: "pages/Articles/AddEditArticles",
        name: "AddEditArticles",
        auth: false,
        exact: true,
      },
      {
        component: "ViewArticles",
        path: "/view-articles",
        componentPath: "pages/Articles/ViewArticles",
        name: "ViewArticles",
        auth: false,
        exact: true,
      },
    ],
  },
  {
    component: "DoctorConsultationReport",
    path: "/doctor-consultation-report",
    componentPath: "pages/ServiceManagement/DoctorConsulationReport",
    name: "DoctorConsultationReport",
    auth: false,
    exact: true,
  },
  {
    component: "DoctorAppointmentList",
    path: "/doctor-appointment-list",
    componentPath: "pages/ServiceManagement/DoctorAppointmentList",
    name: "DoctorAppointmentList",
    auth: false,
    exact: true,
  },
  {
    component: "DoctorViewAppointment",
    path: "/doctor-consultation-details",
    componentPath: "pages/AppointmentManagement/DoctorViewAppointment",
    name: "DoctorViewAppointment",
    auth: false,
    exact: true,
  },
  {
    component: "Video",
    path: "/video-call",
    componentPath: "pages/ServiceManagement/Video",
    name: "Video",
    auth: false,
    exact: true,
  },
  {
    component: "EndVideoCall",
    path: "/end-call",
    componentPath: "pages/ServiceManagement/EndVideoCall",
    name: "EndVideoCall",
    auth: false,
    exact: true,
  },
  {
    component: "MainLayout",
    path: "/banner-management",
    name: "BannerManagement",
    exact: false,
    childrens: [
      {
        component: "BannerManagement",
        path: "/",
        componentPath: "pages/BannerManagement",
        name: "BannerManagement",
        auth: false,
        exact: true,
      },
      {
        component: "AddEditBanner",
        path: "/add-banner",
        componentPath: "pages/BannerManagement/AddEditBanner.jsx",
        name: "AddEditBanner",
        auth: false,
        exact: true,
      },
      {
        component: "AddEditBanner",
        path: "/edit-banner",
        componentPath: "pages/BannerManagement/AddEditBanner.jsx",
        name: "AddEditBanner",
        auth: false,
        exact: true,
      },
      {
        component: "ViewBanner",
        path: "/view-banner",
        componentPath: "pages/BannerManagement/ViewBanner.jsx",
        name: "ViewBanner",
        auth: false,
        exact: true,
      },
    ],
  },
  {
    component: "MainLayout",
    path: "/push-notification",
    name: "NotificationManagement",
    exact: false,
    childrens: [
      {
        component: "NotificationManagement",
        path: "/",
        componentPath: "pages/NotificationManagement",
        name: "NotificationManagement",
        auth: false,
        exact: true,
      },
      {
        component: "NotificationCustomers",
        path: "/customers-list",
        componentPath: "pages/NotificationManagement/NotificationCustomers",
        name: "NotificationCustomers",
        auth: false,
        exact: true,
      },
      {
        component: "AddEditNotification",
        path: "/add-notification",
        componentPath: "pages/NotificationManagement/AddEditNotification",
        name: "AddEditNotification",
        auth: false,
        exact: true,
      },
      {
        component: "AddEditNotification",
        path: "/edit-notification",
        componentPath: "pages/NotificationManagement/AddEditNotification",
        name: "AddEditNotification",
        auth: false,
        exact: true,
      },
      {
        component: "SearchDoctor",
        path: "/search-doctor",
        componentPath: "pages/NotificationManagement/SearchDoctor",
        name: "SearchDoctor",
        auth: false,
        exact: true,
      },
    ],
  },
  {
    component: "MainLayout",
    path: "/onboard-popup",
    name: "OnboardPopup",
    exact: false,
    childrens: [
      {
        component: "OnboardPopup",
        path: "/",
        componentPath: "pages/OnboardPopup",
        name: "OnboardPopup",
        auth: false,
        exact: true,
      },
      {
        component: "AddEditOnboard",
        path: "/add-onboard",
        componentPath: "pages/OnboardPopup/AddEditOnboard.jsx",
        name: "AddEditOnboard",
        auth: false,
        exact: true,
      },
      {
        component: "AddEditBanner",
        path: "/edit-onboard",
        componentPath: "pages/OnboardPopup/AddEditOnboard.jsx",
        name: "AddEditOnboard",
        auth: false,
        exact: true,
      },
    ],
  },
  {
    component: "NotFoundLayout",
    path: "/payment-success",
    name: "PaymentSuccess",
    exact: false,
    childrens: [
      {
        component: "PaymentSuccess",
        path: "/",
        componentPath: "pages/PaymentSuccess",
        name: "PaymentSuccess",
        auth: false,
        exact: true,
      },
    ],
  },
];
export default routers;
