const importImage = (fileName) => {
  return require(`assets/images/${fileName}`);
};

const importIcons = (fileName) => {
  return require(`assets/icons/${fileName}`);
};

export const images = {
  logo: importImage('logo.svg'),
  authbg: importImage('authlayoutbg.png'),
  profile: importIcons('profile.svg'),
  pedigree: importImage('product.svg'),
  royalChain: importImage('royalChainproduct.svg'),
  dog: importImage('dog.png'),
  articles: importImage('articles.png'),
  banner: importImage('banner.png')
};

export const icons = {
  placeHoldedImg: importIcons('placeholder.svg'),
  view: importIcons('view.svg'),
  close: importIcons('close.svg'),
  calender: importIcons('calender.svg'),
  key: importIcons('key.svg'),
  leftArrow: importIcons('arrow-left.svg'),
  search: importIcons('search.svg'),
  actionmenu: importIcons('actionmenu.svg'),
  actionedit: importIcons('actionedit.svg'),
  actiondelete: importIcons('actiondelete.svg'),
  notification: importIcons('notification.svg'),
  eyeOpen: importIcons('eye_opened.svg'),
  eyeClosed: importIcons('eye_closed.svg'),
  userauth: importIcons('userauth.svg'),
  dashboard: importIcons('dashboard.svg'),
  user: importIcons('user.svg'),
  plus: importIcons('plus.svg'),
  order: importIcons('order.svg'),
  product: importIcons('product.svg'),
  customer: importIcons('customer.svg'),
  service: importIcons('service.svg'),
  coupon: importIcons('coupon.svg'),
  blog: importIcons('blog.svg'),
  publish: importIcons('publish.svg'),
  unpublish: importIcons('unpublish.svg'),
  download: importIcons('download-outlined.svg'),
  payment: importIcons('paymentSuccessIcon.svg'),
  cross: importIcons('cross.svg'),
  frontArrow: importIcons('frontArrow.svg'),
  file: importIcons('file.svg'),
  physicalvideo: importIcons('physicalvideo.svg'),
  video: importIcons('video.svg'),
  physical: importIcons('physical.svg'),
  success: importIcons('success.gif'),
  failed: importIcons('failed.gif'),
  push: importIcons('push.svg')
};
