import axios from 'axios';

// import { history } from "../service/helpers";
import { history } from 'helpers';
import { appointmentStatusAdmin } from 'helpers/constant';

//axios Instance
export const axiosInstance = axios.create({
  // baseURL: process.env.REACT_APP_BASE_URL,
  headers: {}
});

//logout
export const logout = () => {
  axiosInstance.defaults.headers.common['Authorization'] = '';
  localStorage.clear();
  history.push('/');
};

export function isValidUrl(string) {
  try {
    new URL(string);
    return true;
  } catch (err) {
    return false;
  }
}

export const imageError = (event) => {
  event.currentTarget.onerror = null;
  event.currentTarget.src = '/images/placeholder.png';
};

export const setMinsHr = (value) => {
  if (Number(value) === 1) {
    return `${value} Hour`;
  }
  return `${value} mins`;
};

// Function to add or update query parameter
export const addQueryParam = (location, history, key, value) => {
  const searchParams = new URLSearchParams(location.search);
  searchParams.set(key, value);

  // Update the URL with the new query parameters
  history.push({ search: searchParams.toString() });
};

// Function to remove all query parameters
export const removeQueryParams = (history) => {
  // Update the URL without any query parameters
  history.push({ search: '' });
};
