import { countries } from "service/country";
import { icons } from "./images";
import moment from "moment";

export const sideBarData = [
  // {
  //   label: "Dashboard sidebar",
  //   to: "/customer-management",
  //   image: icons.dashboard,
  // },
  {
    id: "63230055564043c07f093ed0",
    label: "Customer Management",
    to: "/customer-management",
    image: icons.customer,
  },
  {
    id: "63230079564043c07f093ed6",
    label: "Staff Management",
    to: "/user-management",
    image: icons.user,
  },
  {
    id: "63230020564043c07f093eca",
    label: "Order Management",
    to: "/order-management",
    image: icons.order,
  },
  {
    id: "63230064564043c07f093ed3",
    label: "Product Management",
    to: "/product-management",
    image: icons.product,
  },
  {
    id: "63230044564043c07f093edc",
    label: "Consultation Management",
    to: "/appointment-management",
    image: icons.dashboard,
  },
  {
    id: "632300d0564043c07f093ed9",
    label: "Service Management",
    to: "/service-management",
    image: icons.service,
  },
  {
    id: "63230044564043c07f093ecd",
    label: "Coupon Management",
    to: "/coupon-management",
    image: icons.coupon,
  },
  {
    id: "632300dd564043c07f093edc",
    label: "Articles",
    to: "/articles-management",
    image: icons.blog,
  },
  {
    id: "63230020564043c07f093edd",
    label: "Global Variables",
    to: "/global-variables",
    image: icons.blog,
  },
  {
    id: "63230020564043c07f093ecd",
    label: "Banner Management",
    to: "/banner-management",
    image: icons.calender,
  },
  {
    id: "65f83c7c0b7b7b642d9cb356",
    label: "Push Notification",
    to: "/push-notification",
    image: icons.push,
  },
  {
    id: "6602b2eb142b121c3138aa02",
    label: "Onboard Popup",
    to: "/onboard-popup",
    image: icons.user,
  },
];

export const roleOptions = [
  {
    id: "63230020564043c07f093eca",
    name: "Order Management",
    isSelect: false,
  },
  {
    id: "63230044564043c07f093ecd",
    name: "Coupon Management",
    isSelect: false,
  },
  {
    id: "63230055564043c07f093ed0",
    name: "Customer Management",
    isSelect: false,
  },
  {
    id: "63230064564043c07f093ed3",
    name: "Product Management",
    isSelect: false,
  },
  {
    id: "63230079564043c07f093ed6",
    name: "Staff Management",
    isSelect: false,
  },
  {
    id: "632300d0564043c07f093ed9",
    name: "Service Management",
    isSelect: false,
  },
  {
    id: "63230044564043c07f093edc",
    name: "Consultation Management",
    isSelect: false,
  },
  {
    id: "632300dd564043c07f093edc",
    name: "Articles",
    isSelect: false,
  },
  {
    id: "63230020564043c07f093edd",
    name: "Global Variables",
    isSelect: false,
  },
  {
    id: "63230020564043c07f093ecd",
    name: "Banner Management",
    isSelect: false,
  },
  {
    id: "65f83c7c0b7b7b642d9cb356",
    name: "Push Notification",
    isSelect: false,
  },
  {
    id: "6602b2eb142b121c3138aa02",
    name: "Onboard Popup",
    isSelect: false,
  },
];

export const monthNames = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "June",
  "July",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

export const days = [
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
  "Sunday",
];

export const getOrderStatus = (status) => {
  let statusList = {
    1: "New Order",
    2: "Under Process",
    3: "Confirmed",
    4: "Delivered",
    5: "Cancelled",
  }[status];
  return statusList || "New Order";
};

export const getCountryName = (code) => {
  const country = countries.find((ele) => ele.code === code);
  return country ? country.name : code;
};

export const appointmentStatusAdmin = [
  {
    id: 1,
    name: "Pending",
  },
  {
    id: 2,
    name: "Confirmed",
  },
  {
    id: 3,
    name: "Reschedule Pending",
  },
  {
    id: 4,
    name: "Reschedule Requested",
  },
  {
    id: 5,
    name: "Rescheduled",
  },
  {
    id: 6,
    name: "Cancelled",
  },
  {
    id: 7,
    name: "Completed",
  },
  {
    id: 8,
    name: "User Missed",
  },
  {
    id: 9,
    name: "Doctor Missed",
  },
];

export const appointmentStatusUser = [
  {
    id: 1,
    name: "Confirmed",
  },
  {
    id: 2,
    name: "Reschedule Pending",
  },
  {
    id: 3,
    name: "Reschedule Confirmed",
  },
  {
    id: 4,
    name: "Reschedule Requested",
  },
  {
    id: 5,
    name: "Rescheduled",
  },
  {
    id: 6,
    name: "Cancelled",
  },
  {
    id: 7,
    name: "Completed",
  },
  {
    id: 8,
    name: "User Missed",
  },
  {
    id: 9,
    name: "Doctor Missed",
  },
];

export const consultationCountries = ["SG", "JP", "CA", "IN", "US"];

export const filterConsultType = [
  {
    label: "Video",
    value: 1,
    key: 1,
  },
  {
    label: "Physical",
    value: 2,
    key: 1,
  },
];

export const consultType = [
  {
    label: "Video",
    value: 1,
    key: 1,
  },
  {
    label: "Physical",
    value: 2,
    key: 1,
  },
  {
    label: "View details",
    value: 3,
    key: 2,
  },
];

export const appointmentDoctorStatusName = [
  { class: "pending", value: [1] },
  { class: "confirmed", value: [2, 7] },
  { class: "reschedule", value: [3, 4, 5] },
  { class: "cancelled", value: [6, 8, 9] },
];

export const appointmentCustomerStatusName = [
  { class: "confirmed", value: [1, 2, 7] },
  { class: "reschedule", value: [3, 4, 5] },
  { class: "cancelled", value: [6, 8, 9] },
];

export const getConsultationType = (type) => {
  const currentConsutant = consultType.find((ele) => ele.value === type);
  return currentConsutant?.label;
};

export const getAppointmentStatus = (status, appointment) => {
  const appoitmentStatus = appointment?.find((ele) => ele.id === status);
  return appoitmentStatus?.name;
};

export const rescheduleRequestedBy = {
  doctorOrAdmin: 1,
  user: 2,
};

export const getTime = (time) => moment(time, "HH:mm").format("HH:mm A");

export const getDoctorConsultationType = (type) => {
  const consultationType = consultType
    .filter((ele) => type?.includes(ele.value))
    ?.map((ele) => ele.label);
  return consultationType.toString();
};

export const getCustomerCountries = () =>
  countries.filter((ele) => consultationCountries.includes(ele.code));

export const getDoctorCountries = () =>
  countries.filter((ele) => ele.code === "SG");

export const getAppointmentStatusAdmin = (status) => {
  const adminStatus = appointmentStatusAdmin.find((ele) => ele.name === status);
  console.log(adminStatus, "adminStatus");
  return { ...adminStatus, label: adminStatus.name, value: adminStatus.name };
};

export const getConsultationTypeQuery = (value) => {
  const consultType = filterConsultType.find(
    (ele) => ele.value === Number(value)
  );

  return consultType;
};

export const getServiceSpecialized = (value, service) => {
  const serviceSpec = service?.find((ele) => ele.value === value);
  console.log(serviceSpec, service, value, "serviceSpec");
  return serviceSpec;
};
