import { icons } from 'helpers/images';
import { sideBarData } from 'helpers/constant';

const initialState = {
  staffDetails: {},
  commonPermission: {
    label: 'Dashboard redux',
    to: '/dashboard',
    image: icons.dashboard
  },
  permission: [],
  selectedCustomersNotify: []
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case 'STAFF':
      const permissionsId = payload?.permission?.map((data) => data?._id);
      const temp = sideBarData?.filter((module) =>
        permissionsId.includes(module.id)
      );
      return {
        ...state,
        staffDetails: { ...payload },
        permission: [
          {
            label: 'Dashboard',
            to: '/dashboard',
            image: icons.dashboard
          },
          ...temp
        ]
      };
    case 'NOTIFY':
      return {
        ...state,
        selectedCustomersNotify: [...payload]
      };

    default:
      return state;
  }
};
