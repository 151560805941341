import React, { useState } from "react";
import { useSelector } from "react-redux";
import { NavDropdown } from "react-bootstrap";
import { images } from "helpers/images";
import { logout } from "service/utilities";
import classes from "./styles.module.scss";
import { history } from "helpers";
import CommonModal from "../CommonModal";
import NormalButton from "../Button";

const Navbar = () => {
  const roleName = useSelector((state) => state?.staff?.staffDetails?.roleName);

  const [show, setShow] = useState(false);

  const handleClose = () => {
    setShow(!show);
  };

  const navigateHome = () => {
    history.push("/customer-management");
  };

  return (
    <div className={classes.container}>
      <div>
        <img
          src={images.logo}
          onClick={navigateHome}
          alt="logo"
          className={classes.logo}
        />
      </div>
      <div>
        <div className="d-flex align-items-center">
          <img src={images.profile} alt="profile" />
          <NavDropdown title={roleName} className={classes.navDropdown}>
            <NavDropdown.Item onClick={handleClose}>Logout</NavDropdown.Item>
          </NavDropdown>
        </div>
      </div>
      <CommonModal size="md" show={show} handleClose={handleClose}>
        <div className="text-center">
          <p className="fs-24 fw-500">Are you sure you want to logout ?</p>
          <div className="row mx-5">
            <div className="col-6">
              <NormalButton
                className="button-outline"
                label="No"
                onClick={handleClose}
              />
            </div>
            <div className="col-6">
              <NormalButton
                className="primaryButton"
                label="Yes"
                onClick={() => {
                  handleClose();
                  logout();
                }}
              />
            </div>
          </div>
        </div>
      </CommonModal>
    </div>
  );
};

export default Navbar;
