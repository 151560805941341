import React from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import classes from './styles.module.scss';

const Sidebar = () => {
  const location = useLocation();

  const staffpermission = useSelector((state) => state?.staff?.permission);
  const handleRemovePage = () => {
    localStorage.removeItem('page');
  };
  return (
    <div className={classes.container}>
      {staffpermission.map((menu, index) => {
        return (
          <NavLink
            key={index}
            to={menu.to}
            className={`${classes.navLink}`}
            onClick={handleRemovePage}
          >
            <p
              className={
                location.pathname.includes(menu.to)
                  ? classes.active
                  : classes.navItem
              }
            >
              <img src={menu.image} alt={menu.label} className='pe-3' />
              {menu.label}
            </p>
          </NavLink>
        );
      })}
    </div>
  );
};

export default Sidebar;
