import React from "react";
import { Modal } from "react-bootstrap";

function CommonModal(props) {
  const { show, handleClose, title, children, size = "lg" } = props;
  return (
    <Modal size={size} show={show} onHide={handleClose} centered>
      {!!title && (
        <Modal.Header closeButton>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
      )}
      <Modal.Body>{children}</Modal.Body>
    </Modal>
  );
}

export default CommonModal;
